import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import {
  Modal as ReactstrapModal,
  ModalFooter as ReactstrapModalFooter,
  ModalHeader as ReactstrapModalHeader
} from "reactstrap";

import { FILTER_TYPES } from "themes/projectdx/ThemedScreening/util";
import { ReactComponent as ExitIcon } from "img/icons/cross-in-circle.svg";
import { ReactComponent as DowJonesLogoSvg } from "themes/projectdx/img/dj-full-logo.svg";
import { ReactComponent as ChevronSvg } from "img/right-chevron.svg";
import Flag from "pages/report/Flag";
import EntityCardComponent from "components/molecules/EntityCard";
import { red, grey, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import ReactTooltip from "components/atoms/ReactTooltip";
import SourceLinkComponent from "components/atoms/SourceLink";
import { blue } from "themes/projectdx/colors";

const Modal = styled(ReactstrapModal)`
  max-width: 1024px !important;
`;

const ModalBody = styled.div`
  padding: 18px;
  padding-top: 16px;
  padding-bottom: 0;
  overflow-y: auto;
`;

const ModalFooter = styled(ReactstrapModalFooter)`
  color: ${grey.dark};
  justify-content: space-between !important;
  font-size: ${fontSize.sm};
  margin: 0 16px;
  padding-left: 0;
  padding-right: 0;
  border-top: 1px solid ${grey.rule};
`;

const ModalHeader = styled(ReactstrapModalHeader)`
  border-bottom: none !important;
`;

const ModalTopBorder = styled.div`
  background-color: ${blue.berg};
  width: 1024px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
`;

const ExitModalButton = styled.button`
  all: unset;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${standardColors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
`;

const ExitModalIcon = styled(ExitIcon)`
  height: 20px;
  width: 20px;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }

  circle {
    stroke: transparent;
  }

  path {
    fill: ${blue.berg};
  }
`;

const Section = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid ${grey.rule};
  font-size: ${fontSize.sm};
`;

const EntityOverview = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding-bottom: 16px;
`;

const DowJonesLogo = styled(DowJonesLogoSvg)`
  height: 18px;
`;

const EntityOverviewDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const EntityTitle = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize.lg};
  line-height: 26px;
  margin-top: -3px;
`;

const EntityPills = styled.div`
  display: flex;
  gap: 8px;
`;

const CountryFlag = styled(Flag)`
  width: 30px;
  img {
    width: 24px !important;
    height: 18px !important;
    margin: 0;
    margin-top: 1px !important;
    margin-right: 8px !important;
    border-radius: 2px !important;
  }
`;

const CountryName = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  height: 18px;
`;

const CountryField = styled.div`
  display: flex;
  padding-bottom: 7px;
  padding-top: 4px;
`;

const SpecialInterestSection = styled.div`
  font-size: ${fontSize.sm};
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
`;

const SpecialInterestFlagList = styled.ul`
  margin: 0;
  padding-left: 20px;
`;

const GridDetailsSection = styled(Section)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10px;
`;

const AlternativeNamesSection = styled(Section)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: none !important;
  padding-top: 5px;
`;

const Date = styled.span`
  text-transform: capitalize;
  color: ${grey.dark};
`;

const DateDuration = styled.span`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
`;

const BulletPoint = styled.span`
  height: 4px;
  width: 4px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${grey.dark};
  margin-top: 3px;
  margin-bottom: 3px;
`;

const AkaPair = styled.div`
  margin-left: 14px;
  padding-bottom: 2px;
`;

const ListItem = styled.li`
  list-style-type: none;
  padding-bottom: 2px;
  text-indent: -11px;
  padding-left: 13px;

  &:not(:first-child) {
    padding-top: 12px;
  }
`;

const List = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
`;

const IdentifierPair = styled.div`
  padding-bottom: 2px;
`;

const SectionTitle = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  line-height: 18px;
  padding-bottom: 10px;
`;

const AddressBlock = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize.sm};
  max-width: 220px;
  display: inline-grid;
  margin: 0 39px 16px 0;
`;

const ListsSection = styled(Section)`
  & > dl:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const FlagsSection = styled(Section)`
  & > div {
    margin-bottom: 20px;
  }

  & > div:last-of-type {
    margin-bottom: 12px;
  }

  padding-top: 19px;
  padding-bottom: 8px;
`;

const EntityCard = styled(EntityCardComponent)`
  margin-bottom: 16px;
`;

const IdentifierKey = styled.span`
  color: ${grey.mid};
`;

const LastReviewedLabel = styled.span`
  color: ${grey.mid};
  padding-right: 5px;
`;

const Field = styled(CountryField)`
  padding: 0 !important;
  cursor: pointer;
`;

const Key = styled.div`
  &::first-letter {
    text-transform: capitalize;
  }
`;

const Value = styled.div`
  text-transform: capitalize;
  color: ${grey.dark};
`;

const LocationsSection = styled(GridDetailsSection)`
  row-gap: 10px;
`;

const EntityImage = styled.div`
  background-color: ${({ filterBucketType }) => {
    if (filterBucketType === FILTER_TYPES.confirmed) {
      return red.directRisk;
    }
    if (filterBucketType === FILTER_TYPES.unconfirmed) {
      return grey.mid;
    }
    return grey.ghost;
  }};
  height: 74px;
  min-width: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
`;

const ExpandableHeader = styled.div`
  border-top: 1px solid ${grey.rule};
  color: ${grey.mid};
  font-size: ${fontSize.md};
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${family.interRegular};
  cursor: pointer;
`;

const CollapseButton = styled.button`
  outline: none;
  background: none;
  border: none;
  border-radius: 20px;
  width: 28px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  &:focus,
  &:active {
    outline: none;
    background-color: ${grey.light};
  }
`;

const CollapseArrow = styled(ChevronSvg)`
  height: 16px;
  width: 16px;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? "rotate(0deg)" : "rotate(90deg)"};

  path {
    fill: ${grey.mid};
  }
`;

const SourceLink = styled(SourceLinkComponent)`
  font-size: ${fontSize.sm};
`;

const Container = styled.div`
  padding-bottom: 11px;
  color: ${grey.dark};
  font-size: ${fontSize.sm};
  white-space: pre-wrap;
`;

const FlagBanner = styled.div`
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  border-bottom: none !important;
  border: 1px solid ${({ isActive }) => (isActive ? red.directRisk : grey.mid)};
  color: ${({ isActive }) => (isActive ? red.directRisk : grey.mid)};
  font-size: ${fontSize.md};
  background-color: ${standardColors.white};
  padding: 0 16px;
  font-family: ${family.interSemiBold};
`;

const StatusLabel = styled.div`
  font-family: ${family.interRegular};
`;

const FlagLocations = styled.div`
  border-top: 1px solid ${grey.rule};
  font-size: ${fontSize.sm};
  font-family: ${family.interRegular};
  padding-top: 7px;
  padding-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;

  & > div {
    margin-right: 55px;
  }
`;

const FlagLocation = styled.div`
  display: flex;
  cursor: pointer;
`;

const Country = styled(CountryField)`
  padding-top: 0;
  padding-left: 10px;
`;

const FlagBannerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
`;

const Note = styled.div`
  text-align: left;
`;

const CountryFlagTooltip = styled.div`
  width: 365px;
  padding: 4px;
  text-align: left;
  font-size: ${fontSize.sm};
`;

const CountryFlagTooltipHeader = styled.div`
  border-bottom: 1px solid ${grey.rule};
  font-family: ${family.interSemiBold};
  line-height: 24px;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const CountryFlagTooltipContent = styled.div`
  padding-top: 4px;
`;

const IdentifiersSection = styled.div`
  padding-top: 0;
  margin-top: -5px;
  padding-bottom: 10px;
  padding-top: 0;
  & > dl {
    margin-top: 10px;
  }

  dl:first-of-type {
    margin-top: 0 !important;
  }
`;

const WebsitesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AdditionalCount = styled.span`
  color: ${grey.mid};
  white-space: pre;
`;

const AdditionalInfo = styled.div`
  color: ${grey.mid};
  padding-bottom: 4px;
`;

const AddressesSection = styled.div`
  padding-bottom: 10px;
`;

const ImageLinks = styled.div`
  padding-bottom: 10px;
  margin-top: -5px;
`;

const PlaceholderText = styled.div`
  color: ${grey.ghost};
  padding: 10px 0;
`;

const Tooltip = styled(ReactTooltip)`
  display: inline;
`;

const NoneIdentified = styled.div`
  color: ${grey.ghost};
  font-style: italic;
`;

const CountryNames = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  padding-top: 4px;
`;

const SpecialInterestFlagContent = styled.div`
  padding: 10px 0;
`;

const BackButtonText = styled.div`
  color: ${standardColors.white};
  font-size: ${fontSize.lg};
  line-height: 20px;
`;

const BackButton = styled.button`
  all: unset;
  display: flex;
  gap: 10px;
  position: absolute;
  top: 17px;
  left: 20px;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;

    ${BackButtonText} {
      border-bottom: 1px solid ${standardColors.white};
    }
  }
`;

const BackButtonChevron = styled(ChevronSvg)`
  margin-top: 1px;
  height: 18px;
  transform: rotate(180deg);
`;

const ModalHeaderTextContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 20px;
`;

const modalHeaderTextStyles = {
  fontSize: fontSize.lg,
  color: standardColors.white,
  fontFamily: family.interSemiBold
};

const ModalHeaderTextPrimary = styled.h1`
  ${modalHeaderTextStyles}
`;

const ModalHeaderTextSecondary = styled.h1`
  ${modalHeaderTextStyles}
  opacity: 0.6;
`;

const RoleDetailsSection = styled(Section)`
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-row-gap: 10px;
`;

const Divider = styled.hr`
  width: 100%;
  margin: 10px 0;
  height: 2px;
  border-top-color: ${grey.rule};
`;

const Category = styled.strong`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
`;

const LowerThresholdItem = styled.li`
  ::before {
    content: "– "; /* Add em dash before each list item */
  }
  list-style-type: none;
  padding: 0;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  padding-left: 1rem;
  font-weight: bold;
`;

/** ClassNameOverrides */

const modalContent = css`
  border-radius: 11px;
  border: none;
`;

const dataField = css`
  dt {
    margin-bottom: 10px;
  }
`;

const countryFlagDataField = css`
  dt {
    margin-bottom: 5px;
  }
`;

const flagPillsContainer = css`
  padding-top: 0 !important;
  display: block !important;

  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const associates = css`
  padding-top: 0;
  &:last-of-type {
    padding-bottom: 10px;
  }
`;

const S = {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTopBorder,
  Category,
  Section,
  SpecialInterestSection,
  SpecialInterestFlagList,
  EntityOverview,
  ExitModalIcon,
  DowJonesLogo,
  EntityOverviewDetails,
  EntityTitle,
  EntityPills,
  CountryField,
  CountryName,
  CountryFlag,
  GridDetailsSection,
  AlternativeNamesSection,
  Date,
  DateDuration,
  BulletPoint,
  AkaPair,
  ListItem,
  List,
  IdentifierPair,
  SectionTitle,
  AddressBlock,
  ListsSection,
  FlagsSection,
  Divider,
  EntityCard,
  IdentifierKey,
  LastReviewedLabel,
  Field,
  Key,
  Value,
  LocationsSection,
  EntityImage,
  ExpandableHeader,
  CollapseArrow,
  CollapseButton,
  SourceLink,
  Container,
  FlagBanner,
  StatusLabel,
  FlagLocations,
  Country,
  FlagBannerTitle,
  FlagLocation,
  Note,
  CountryFlagTooltip,
  CountryFlagTooltipHeader,
  CountryFlagTooltipContent,
  IdentifiersSection,
  WebsitesContainer,
  AdditionalCount,
  AdditionalInfo,
  AddressesSection,
  ImageLinks,
  PlaceholderText,
  Tooltip,
  NoneIdentified,
  CountryNames,
  SpecialInterestFlagContent,
  BackButton,
  BackButtonText,
  BackButtonChevron,
  ModalHeaderTextContainer,
  ModalHeaderTextPrimary,
  ModalHeaderTextSecondary,
  ExitModalButton,
  LowerThresholdItem,
  RoleDetailsSection
};

export const classNameOverrides = {
  modalContent,
  dataField,
  countryFlagDataField,
  flagPillsContainer,
  associates
};

export default S;
